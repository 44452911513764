import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import "./styles/css/main.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Body from "./components/Body";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import VerifyToken from "./components/TokenSent";
import VerifyTokenPage from "./components/VerifyTokenPage";
import { AuthProvider } from "./components/AuthContext";
import UserProfile from "./pages/UserProfile";
import TeamProfile from "./pages/TeamProfile";
import PrivateRoute from "./components/PrivateRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Header />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Body />
              </>
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/team"
            element={
              <PrivateRoute allowedUserTypes={[1]}>
                <TeamProfile />
              </PrivateRoute>
            }
          />
          <Route path="/verify-token" element={<VerifyToken />} />
          <Route path="/validar/:token" element={<VerifyTokenPage />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  );
}


export default App;
