import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/css/main.css';
import { useAuth } from "../components/AuthContext";

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await login(username, password, rememberMe);
      navigate('/profile');
    } catch (error) {
      setError(error.message);
    }
  }

  return (
    <div className="login-card">
      <h2>Inicia sesión</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            className="form-control"
            placeholder="Ingresa tu username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Contraseña</label>
          <input
            type="password"
            className="form-control"
            placeholder="Ingresa tu contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Recordarme</label>
        </div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <button type="submit" className="btn-login">Iniciar Sesión</button>
        <div className="signup-link">
          <p>
            ¿No tienes una cuenta? <Link to="/signup">Regístrate</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
