import logo from "../images/CSIM_logo_white.png";
import bgMenuImage from "../images/bg_menu.png";
import React,{useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Navbar() {

  const[isOpen, setIsOpen] = useState(false);

  // desactivo el scroll en mobile cuando se abre el navbar

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  return (
    <div className="Header-main">
      <nav
        className="navbar navbar-expand-lg"
        style={{
          backgroundColor: "#b21918",
          padding: "20px",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <a href="/" className="navbar-brand">
            <img src={logo} alt="Logo" height="80" />
          </a>
        </div>

        <div className={`navbar-nav ${isOpen ? "open" : ""}`}>
          <a href="/" className="nav-link">
            HOME
          </a>

          <div className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="torneosDropdown">
              TORNEOS
            </a>
            <div className="dropdown-menu" aria-labelledby="torneosDropdown">
              <a className="dropdown-item" href="#">
                NOMBRE TORNEO 1
              </a>
              <a className="dropdown-item" href="#">
                NOMBRE TORNEO 2
              </a>
            </div>
          </div>

          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="embajadoresDropdown"
            >
              EMBAJADORES
            </a>
            <div className="dropdown-menu" aria-labelledby="embajadoresDropdown">
              <a className="dropdown-item" href="#">
                STREAMEA CON NOSOTROS
              </a>
              <a className="dropdown-item" href="#">
                PACK DE STREAMING
              </a>
              <a className="dropdown-item" href="#">
                EMBAJADOR 1
              </a>
              <a className="dropdown-item" href="#">
                EMBAJADOR 2
              </a>
            </div>
          </div>

          <div className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="mediaDropdown">
              MEDIA
            </a>
            <div className="dropdown-menu" aria-labelledby="mediaDropdown">
              <a className="dropdown-item" href="#">
                FOTOS
              </a>
              <a className="dropdown-item" href="#">
                VIDEOS
              </a>
              <a className="dropdown-item" href="#">
                PACK PRENSA
              </a>
            </div>
          </div>

          <a href="#" className="nav-link">
            CONTACTO
          </a>
        </div>

        <div className={`nav-toggle ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(!isOpen)}>
          <div className="bar"></div>
        </div>
        
      </nav>
    </div>
  );
}
