import {
  FaInstagram,
  FaFacebookF,
  FaYoutube,
  FaXTwitter,
  FaTwitch,
} from "react-icons/fa6";
import logo from "../images/CSIM_logo_white.png";
import React from "react";
import "../styles/css/main.css";


export default function Footer() {
  return (
    <footer className="footer-container">
        <div className="logo-container">
          <img src={logo} alt="Logo" height="50" />
        </div>
        
        <div className="text-footer-container">
          <p>©2024 Keen Con - CS Iberian Masters</p>
        </div>
        
        <div className="social-icons">
          <a href="https://www.twitter.com/"><FaXTwitter /></a>
          <a href="https://www.facebook.com/"><FaFacebookF /></a>
          <a href="https://www.youtube.com/"><FaYoutube /></a>
          <a href="https://www.instagram.com/"><FaInstagram /></a>
          <a href="https://www.twitch.tv/"><FaTwitch /></a>
        </div>
    </footer>
  );
}
