import React from "react";
import "../styles/css/main.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import team1 from "../images/logos-teams/liquid.png";
import team2 from "../images/logos-teams/mouz.png";
import team3 from "../images/logos-teams/cloud9.png";
import team4 from "../images/logos-teams/navi.png";

export default function TournamentCard({ link, fixedResult, matchDate }) {
  const title = "TORNEO 1";
  const subtitleLink = "VER TODOS";

  return (
    <div className="tournament-card">
      <div className="torneos"> 
      MIS TORNEOS
      </div>
      <div className="header">
        <FaAngleLeft className="arrow" />
        <h3>{title}</h3>
        <FaAngleRight className="arrow" />
      </div>
      <div className="subtitle">
        <button type="button">{link}</button>
      </div>
      <div className="matches">
        <div className="match">
          <img src={team1} alt="Team 1 Logo" />
          <div>
            <span className="result">{fixedResult}</span>
            <span className="date">{matchDate}</span>
          </div>
          <img src={team2} alt="Team 2 Logo" />
        </div>
        <hr className="match-divider" />
        <div className="match">
          <img src={team3} alt="Team 1 Logo" />
          <div>
            <span className="result">{fixedResult}</span>
            <span className="date">{matchDate}</span>
          </div>
          <img src={team4} alt="Team 2 Logo" />
        </div>
        <hr className="match-divider" />
        <div className="match">
          <img src={team3} alt="Team 1 Logo" />
          <div>
            <span className="result">{fixedResult}</span>
            <span className="date">{matchDate}</span>
          </div>
          <img src={team1} alt="Team 2 Logo" />
        </div>
      </div>
      <div className="footer">
        <button type="button">{subtitleLink}</button>
      </div>
    </div>
  );
}