import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backgroundImage1 from "../images/img-home/arma.png";
import backgroundImage2 from "../images/img-home/web_foto_captura.png";
import logo from "../images/CSIM_logo_white.png";
import inscripcion from "../images/img-home/web_foto_inscripcion.png";
import "../styles/css/home.css";

const Body = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const calculateTimeLeft = () => {
    const difference = new Date("2024-03-24") - new Date();
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);
      setCountdown({ days, hours, minutes, seconds });
    } else {
      setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="contenedor-home-principal">
      <div
        className="home-background1"
        style={{ backgroundImage: `url(${backgroundImage1})` }}
      >
        <div className="home1">
          <h1>CS IBERIAN MASTERS BY KEENCON</h1>
          <h2>CONQUISTA LA PENINSULA</h2>
          <p>
            Reserva tu plaza, forma tu equipo y<br className="mobile-br"/>
            enfrentate a la élite de España y Portugal en
            <br className="mobile-br"/>
            dos torneos online que culminarán en unas
            <br className="mobile-br"/>
            épicas finales offline en julio.
          </p>

          <Link to="/signup" className="button-reserva">
            RESERVA TU PLAZA
          </Link>
          <div className="countdown">
            <div>
              <span>{countdown.days}</span>
              <span>Días</span>
            </div>
            <div>
              <span>{countdown.hours}</span>
              <span>H</span>
            </div>
            <div>
              <span>{countdown.minutes}</span>
              <span>MIN</span>
            </div>
            <div>
              <span>{countdown.seconds}</span>
              <span>SEC</span>
            </div>
          </div>
        </div>
      </div>

      <div className="black-container">
        <h2>
          ¡BIENVENIDO A LA COMPETICIÓN DEFINITIVA DE COUNTER-STRIKE 2 PARA LOS
          <br className="mobile-br"/> JUGADORES MÁS EXPERIMENTADOS DE LA PENÍNSULA IBÉRICA!
        </h2>
        <p>
          ALÍSTATE PARA LA CS IBERIAN MASTERS, DISEÑADA EXCLUSIVAMENTE PARA LA
          ÉLITE DE JUGADORES DE <br className="mobile-br"/> ESPAÑA Y PORTUGAL. RESERVA TU PLAZA,
          FORMA TU EQUIPO Y DESAFÍA A TUS RIVALES EN DOS <br className="mobile-br"/> APASIONANTES
          TEMPORADAS ONLINE DE 2 MESES CADA UNA, DE FEBRERO A MAYO DE 2024. LA{" "}
          <br className="mobile-br"/> BATALLA FINAL LLEGARÁ EN JULIO CON LA CS IBERIAN MASTERS FINALS
          EN UN ESPECTACULAR <br className="mobile-br"/> RECINTO LEVANTADO PARA LA OCASIÓN.
        </p>
      </div>

      <div className="inscribite-container">
        <h1>
          <strong>Plazas</strong>
        </h1>
        <p>
          <strong>¡Actúa rápido!</strong> ¡Solo hay 32 slots para equipos de
          mínimo 5 <br className="mobile-br"/> jugadores que estén dispuestos a grabar su nombre en
          la historia <br className="mobile-br"/> de CS Iberian Masters! <br className="mobile-br"/>
          <strong> Exclusivo para residentes en España y Portugal</strong>.
        </p>
        <h1>
          <strong>Inscripción</strong>
        </h1>
        <p>
          <strong>Matrícula inicial:</strong> 13.99 € por jugador. <br className="mobile-br"/>
          <strong>Suscripción mensual:</strong> 9.99 € al mes por jugador.
        </p>
        <div className="img-container">
          <img src={inscripcion} alt="Inscripción" className="inscripcion" />
          <img src={logo} alt="Logo" className="logo-overlay" />
          <Link to="/signup">
            <button className="inscribirse-button">INSCRÍBETE</button>
          </Link>
        </div>
      </div>
      <div>
        <img src={backgroundImage2} alt="Fondo" className="img-lower" />
      </div>
      <div className="black-container-lower">
        <p>
          ¡Vuelve la guerra a la península Ibérica! ¡Desempolva tus armas,
          perfecciona <br className="mobile-br"/> tu estrategia y demuestra que eres el mejor
          Counter-Striker de la península!
        </p>
      </div>
    </div>
  );
};

export default Body;
