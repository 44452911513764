import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const VerifyTokenPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('Verificando tu cuenta...');
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await fetch(`https://imapi.keencon.org/token-validation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();

        if (response.ok && data.status.code === 0) {
          setVerificationStatus(data.message || 'Cuenta verificada correctamente.');
        } else {
          throw new Error(data.message || 'Error al verificar el token. Por favor, intenta de nuevo.');
        }
      } catch (error) {
        setVerificationStatus(error.message || 'Error al verificar el token. Por favor, intenta de nuevo.');
      } finally {
        setIsVerifying(false);
      }
    };

    verifyToken();
  }, [token, navigate]);

  useEffect(() => {
    if (!isVerifying) {
      const timeout = setTimeout(() => {
        navigate('/login', { replace: true, state: { message: verificationStatus } });
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [isVerifying, navigate, verificationStatus]);

  return (
    <div className="card-token">
      <h1>Estado de la Verificación</h1>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default VerifyTokenPage;
