import {
  FaInstagram,
  FaFacebookF,
  FaYoutube,
  FaXTwitter,
  FaTwitch,
  FaArrowRightFromBracket,
} from "react-icons/fa6";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import LanguageSelector from "./LanguageSelector";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

export default function Header() {
  const { currentUser, logout } = useAuth();

  const languages = ["ES", "PT"];

  const handleLanguageChange = (selectedLanguage) => {
    console.log(`Cambiando a ${selectedLanguage}`);
  };

  useEffect(() => {
    // Este efecto se ejecutará cada vez que `currentUser` cambie.
    console.log("El usuario ha cambiado:", currentUser);
  }, [currentUser]); // Dependencias del efecto

  return (
    <div>
      <div className="Header-top d-flex justify-content-end align-items-center">
        <div className="Socials">
          <div className="social-icons-top">
            <a href="https://www.twitter.com/" className="text-white">
              <FaXTwitter />
            </a>
            <a href="https://www.facebook.com/" className="text-white">
              <FaFacebookF />
            </a>
            <a href="https://www.youtube.com/" className="text-white">
              <FaYoutube />
            </a>
            <a href="https://www.instagram.com/" className="text-white">
              <FaInstagram />
            </a>
            <a href="https://www.twitch.tv/" className="text-white">
              <FaTwitch />
            </a>
          </div>
        </div>
        {currentUser && (
          <>
            <div className="mx-2" style={{ color: "white" }}>
              Hola {currentUser.nickname}
            </div>
            <Link to="/profile">
              <button className="Perfil">PERFIL</button>
            </Link>
            {currentUser.userType === 1 && (
              <Link to="/team">
                <button className="Acceso">EQUIPO</button>
              </Link>
            )}
            <FaArrowRightFromBracket
              className="Logout"
              onClick={logout}
              style={{ cursor: "pointer" }}
            />
          </>
        )}
        {!currentUser && (
          <>
            <Link to="/signup">
              <button className="Registro">REGISTRO</button>
            </Link>
            <Link to="/login">
              <button className="Acceso">ACCESO</button>
            </Link>
          </>
        )}
        <div className="Selector-idiomas">
          <LanguageSelector
            languages={languages}
            currentLanguage="ES"
            onLanguageChange={handleLanguageChange}
          />
        </div>
      </div>
    </div>
  );
}
