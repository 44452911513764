import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Inicializamos el estado de carga

  useEffect(() => {
    const userToken = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
    const userNickname = localStorage.getItem("userNickname") || sessionStorage.getItem("userNickname");
    const userType = Number(localStorage.getItem("userType")) || Number(sessionStorage.getItem("userType"));
    const userId = localStorage.getItem("userId") || sessionStorage.getItem("userId");

    // Si todos los valores necesarios están presentes, actualiza el estado del currentUser
    if (userToken && userNickname && userType != null && userId) {
      setCurrentUser({
        token: userToken,
        nickname: userNickname,
        userType,
        userId,
      });
    }
    
    setLoading(false);
  }, []);

  const login = async (nickname, password, rememberMe) => {
    try {
      const response = await fetch("https://imapi.keencon.org/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Username: nickname,
          Password: password,
          RememberMe: rememberMe,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok && data.status.code === 0) {
        const token = data.data.token;
        const { userId, userTypes } = data.userStatus;
        const user = {
          token,
          userId,
          nickname,
          userTypes,
        };
        setCurrentUser(user);
  
        // Guardar el token y el userId en localStorage o sessionStorage
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem("userToken", token);
        storage.setItem("userId", userId);
        storage.setItem("userNickname", nickname);
        storage.setItem("userType", parseInt(userTypes)); // Convertir userTypes a entero
  
        return true;
      } else {
        const errorMessage =
          data.status.code === -2
            ? "Contraseña incorrecta. Por favor, verifica tu contraseña."
            : data.status.message ||
              "Inicio de sesión fallido. Verifica tus credenciales.";
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error al enviar la solicitud de inicio de sesión:", error);
      throw error;
    }
  };  

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setCurrentUser(null);
  };

  const register = async (userData) => {
    try {
      const response = await fetch("https://imapi.keencon.org/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Username: userData.Username,
          Nickname: userData.nickname,
          FullName: userData.FullName,
          Country: userData.Country,
          DateOfBirth: userData.DateOfBirth,
          Email: userData.Email,
          Password: userData.Password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error al registrar usuario");
      }
      return true;
    } catch (error) {
      console.error("Error al registrar usuario:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    login,
    logout,
    loading,
    register,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
