import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaCamera } from "react-icons/fa";
import Select from "react-select";
import "../styles/css/main.css";
import banner from "../images/banner_team.png";
import pfp from "../images/pfp_team.png";
import TournamentCard from "../components/TournamentCard";

const api = "https://imapi.keencon.org";
const getToken = () =>
  localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

const TeamProfile = () => {
  const [profilePic, setProfilePic] = useState(pfp);
  const [bannerPic, setBannerPic] = useState(banner);
  const [activeTab, setActiveTab] = useState("datos");
  const location = useLocation();
  const [playersOptions, setPlayersOptions] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isInviting, setIsInviting] = useState(false);
  const [playerToInvite, setPlayerToInvite] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);



  const fetchCurrentTeam = async () => {
    const token = getToken();
  
    try {
      const response = await fetch(api + "/teams/current", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error(
          "Hubo un problema al obtener el equipo actual:",
          response.statusText
        );
        return;
      }
  
      const teamData = await response.json();
  
      if (teamData && teamData.data && teamData.data.id) {
        await fetchTeamMembers(teamData.data.id);
      } else {
        console.log("El usuario no pertenece a ningún equipo.");
      }
    } catch (error) {
      console.error("Error al obtener el equipo actual:", error);
    }
  };
  
  const fetchTeamMembers = async (teamId) => {
    const token = getToken();
  
    try {
      const response = await fetch(`${api}/teams/${teamId}/players`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        console.error("Hubo un problema al obtener los miembros del equipo");
        return;
      }
  
      const memberData = await response.json();
      console.log(memberData); // Esto debería mostrar ahora específicamente los datos de los miembros del equipo
  
      if (memberData && memberData.data) {
        setTeamMembers(memberData.data.map(member => ({
          userId: member.id,
          realName: member.fullName,
          nickname: member.nickname,
          membershipActive: true
        })));
      } else {
        console.log("No se encontraron miembros para este equipo.");
        setTeamMembers([]);
      }
    } catch (error) {
      console.error("Error al obtener los miembros del equipo:", error);
    }
  };
  
  

  const fetchPendingRequests = () => {
    const token = getToken();

    if (!token) {
      console.error("No se encontró un token de usuario.");
      return;
    }

    fetch(api + "/teams/requests", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && Array.isArray(data.data)) {
          setPendingRequests(data.data);
        } else {
          setPendingRequests([]);
        }
      })
      .catch((error) => {
        console.error("Error al obtener solicitudes pendientes:", error);
        setPendingRequests([]);
      });
  };

  useEffect(() => {
    const currentHash = location.hash.replace("#", "");
    setActiveTab(currentHash || "Administrar");
  }, [location]);

  useEffect(() => {
    fetchPlayers();
    fetchPendingRequests();
    fetchCurrentTeam();
  }, [fetchCurrentTeam]); // Agrega fetchCurrentTeam al array de dependencias
  

  const fetchPlayers = () => {
    const token = getToken();

    if (!token) {
      console.error(
        "No se encontró ningún token en el localStorage ni en sessionStorage."
      );
      return;
    }

    fetch(api + "/players", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const options = data.data.map((user) => ({
          value: user.id,
          label: user.nickname,
        }));
        setPlayersOptions(options);
        console.log("Jugadores obtenidos:", options);
      })
      .catch((error) => console.error("Error al obtener jugadores:", error));
  };

  const resolveRequest = (requestId, accept) => {
    const token = getToken();

    const requestBody = {
      Id: requestId,
      Accept: accept,
    };

    fetch(api + "/teams/request-resolve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Hubo un problema con la petición Fetch");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Solicitud resuelta", data);
        // Actualizar el estado de las solicitudes pendientes después de resolver la solicitud
        setPendingRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== requestId)
        );
      })
      .catch((error) => {
        console.error("Error al resolver la solicitud:", error);
      });
  };

  useEffect(() => {
    // Si no hay solicitudes pendientes después de cargar, actualiza el estado para reflejarlo
    if (pendingRequests.length === 0) {
      setPendingRequests([]);
    }
  }, [pendingRequests.length]); // Incluye pendingRequests.length en el array de dependencias

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerPicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const changeTab = (tabName) => {
    window.location.hash = tabName;
  };

  const removeTeamMember = (userId) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de que deseas eliminar a este integrante del equipo?"
    );
    if (isConfirmed) {
      setTeamMembers((prevMembers) =>
        prevMembers.filter((member) => member.id !== userId) // Asegúrate de que aquí uses el identificador correcto
      );
    }
};

  const handlePlayerChange = (selectedOption) => {
    setPlayerToInvite(selectedOption);
  };

  const invitePlayer = async () => {
    if (!playerToInvite) {
      alert("Por favor, selecciona un equipo para enviar una solicitud.");
      return;
    }
    setIsInviting(true); // Indica que el proceso de invitación ha comenzado
    const playerId = playerToInvite.value;

    const token = getToken();

    try {
      const response = await fetch(`${api}/teams/request-player/${playerId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          "Hubo un problema al enviar la solicitud de invitación"
        );
      }

      alert(`Solicitud enviada a: ${playerToInvite.label}`);
      console.log(`Solicitud enviada a: ${playerToInvite.label}`);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      alert("Error al enviar la solicitud. Por favor, inténtalo de nuevo.");
    } finally {
      setIsInviting(false); // Restablece el estado independientemente del resultado
    }
  };

  return (
    <div className="user-profile">
      <div className="profile-picture">
        <input
          type="file"
          id="profile-upload"
          className="upload-input"
          onChange={handleProfilePicChange}
        />
        <label htmlFor="profile-upload" className="profile-overlay"></label>
        <button
          className="upload-btn"
          onClick={() => document.getElementById("profile-upload").click()}
        >
          <FaCamera color="#ffffff" />
        </button>
        <img src={profilePic} alt="Profile" className="profile-image" />
      </div>
      <div className="banner">
        <input
          type="file"
          id="banner-upload"
          className="upload-input"
          onChange={handleBannerPicChange}
        />
        <label htmlFor="banner-upload" className="banner-overlay"></label>
        <button
          className="upload-btn"
          onClick={() => document.getElementById("banner-upload").click()}
        >
          <FaCamera color="#ffffff" />
        </button>
        <img src={bannerPic} alt="Banner" className="banner-image" />
      </div>
      <div className="user-profile">
        <nav className="mini-navbar">
          <ul>
            <li
              className={`nav-link ${
                activeTab === "Administrar" ? "active" : ""
              }`}
              onClick={() => changeTab("Administrar")}
            >
              ADMINISTRAR EQUIPO
            </li>
            <li
              className={`nav-link ${
                activeTab === "Participaciones" ? "active" : ""
              }`}
              onClick={() => changeTab("#")}
            >
              PARTICIPACIONES/TORNEOS
            </li>
            <li
              className={`nav-link ${
                activeTab === "membresia" ? "active" : ""
              }`}
              onClick={() => changeTab("#")}
            >
              MEMBRESÍA/PAGOS
            </li>
          </ul>
        </nav>
        <div>
          {activeTab === "Administrar" && (
            <div className="players-container">
              <ul>
                <div className="team-member-title">
                  <div className="member-info">
                    <div className="member-details">
                      <div className="integrantes-label">Integrantes</div>
                    </div>
                    <div className="member-details">
                      <div className="membresia-label">Membresía</div>
                    </div>
                  </div>
                </div>
                {teamMembers.map((member, index) => (
                  <li key={member.id} className="team-member">
                    <div className="member-info">
                      <span className="member-number">{index + 1}</span>
                      <div className="member-details">
                        <div className="nombre-real">{member.realName}</div>
                      </div>
                      <div className="member-details">
                        <div className="nickname">{member.nickname}</div>
                      </div>
                      <div className="member-details">
                        <div
                          className={`membresia ${
                            member.membershipActive ? "activada" : "desactivada"
                          }`}
                        >
                          {member.membershipActive ? "ACTIVADA" : "DESACTIVADA"}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => removeTeamMember(member.id)}
                      className="btn-eliminar"
                    >
                      Eliminar
                    </button>
                  </li>
                ))}

                {/* Lista de solicitudes pendientes */}
                <div className="team-member-title">
                  <div className="member-info">
                    <div className="member-details">
                      <div className="integrantes-label">
                        Solicitudes Pendientes
                      </div>
                    </div>
                  </div>
                </div>
                {pendingRequests.length > 0 ? (
                  pendingRequests.map((request, index) => {
                    return (
                      <li key={request.id} className="team-member">
                        <div className="member-info">
                          {/* Mostrar nombre del jugador si la solicitud no fue creada por el equipo */}
                          <div className="nombre-real">
                            {request.playerNickname}
                          </div>
                          <div className="member-details">
                            {/* Si la solicitud fue creada por el equipo, mostrar solo el botón Eliminar */}
                            {request.createdByTeam ? (
                              <button
                                onClick={() =>
                                  resolveRequest(request.id, false)
                                }
                                className="btn-eliminar"
                              >
                                Eliminar
                              </button>
                            ) : (
                              // Si la solicitud fue creada por el jugador, muestra los botones Aceptar y Rechazar
                              <>
                                <button
                                  onClick={() =>
                                    resolveRequest(request.id, true)
                                  }
                                  className="btn-aceptar"
                                >
                                  Aceptar
                                </button>
                                <button
                                  onClick={() =>
                                    resolveRequest(request.id, false)
                                  }
                                  className="btn-eliminar"
                                >
                                  Rechazar
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className="nombre-real">{"---"}</div>
                )}
              </ul>

              <div className="playerSelector">
                <label>Enviar una solicitud a un jugador</label>
                <div className="invite-container">
                  <Select
                    options={playersOptions}
                    onChange={handlePlayerChange}
                    placeholder="Ingrese el nombre del jugador..."
                    isClearable
                    isSearchable
                    value={playersOptions.find(
                      (option) => option.value === playerToInvite
                    )}
                  />
                  <button
                    onClick={invitePlayer}
                    className="inviteButton"
                    disabled={isInviting}
                  >
                    {isInviting ? "Enviando..." : "Enviar solicitud"}
                  </button>
                </div>
              </div>
              <TournamentCard
                link="MIS PRÓXIMOS PARTIDOS"
                fixedResult="VS"
                matchDate="2024-03-12"
              />
            </div>
          )}
          {activeTab === "Participaciones" && (
            <div>Contenido de Participaciones/Torneos</div>
          )}
          {activeTab === "membresia" && <div>Contenido de Membresía/Pagos</div>}
        </div>
      </div>
    </div>
  );
};

export default TeamProfile;
