import React from 'react';

const LanguageSelector = ({ languages, currentLanguage, onLanguageChange }) => {
  return (
    <select value={currentLanguage} onChange={(e) => onLanguageChange(e.target.value)}>
      {languages.map((language) => (
        <option key={language} value={language}>
          {language}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;