import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const VerifyToken = () => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const isVerifying = localStorage.getItem('verifyingToken');
    if (isVerifying) {
      setIsValid(true);
      localStorage.removeItem('verifyingToken');
    }
  }, []);

  useEffect(() => {
    if (isValid) {
      const timeout = setTimeout(() => {
        setIsValid(false);
        clearTimeout(timeout);
      }, 2000);
    }
  }, [isValid]);

  return (
    <div className="card-token">
      <h2>Verificar Cuenta</h2>
      <p>Se ha enviado un correo electrónico con un enlace para verificar tu cuenta. Por favor, revisa tu bandeja de entrada y haz clic en el enlace para activar tu cuenta.</p>
      <p>Si no recibes el correo electrónico, asegúrate de revisar tu carpeta de spam o <Link to="/resend-verification">haz clic aquí para reenviar el correo electrónico de verificación.</Link></p>
    </div>
  );
};

export default VerifyToken;
