import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ children, allowedUserTypes }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return null;
  }

  // Verifica si el usuario está autenticado y si tiene el tipo de usuario permitido
  const isUserAllowed = currentUser && (!allowedUserTypes || allowedUserTypes.includes(currentUser.userType));

  return isUserAllowed ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
