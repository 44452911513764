import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { FaCamera } from "react-icons/fa";
import "../styles/css/main.css";
import banner from "../images/banner.png";
import pfp from "../images/pfp.png";
import TournamentCard from "../components/TournamentCard";

const api = "https://imapi.keencon.org";
const getToken = () =>
  localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

const UserProfile = () => {
  const [profilePic, setProfilePic] = useState(pfp);
  const [bannerPic, setBannerPic] = useState(banner);
  const [activeTab, setActiveTab] = useState("datos");
  const location = useLocation();
  const [teamOptions, setTeamsOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [teamToInvite, setTeamToInvite] = useState(null);
  const [isInviting, setIsInviting] = useState(false);

  const fetchCurrentTeam = async () => {
    const token = getToken();

    try {
      const response = await fetch(api + "/teams/current", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error(
          "Hubo un problema al obtener el equipo actual:",
          response.statusText
        );
        setSelectedTeam({ label: "---", value: null });
        return;
      }

      const data = await response.json();

      // Verificar si hay datos en la respuesta antes de establecer el equipo seleccionado
      if (data && data.data) {
        setSelectedTeam({ label: data.data.name, value: data.data.id });
      } else {
        console.log("El usuario no pertenece a ningún equipo.");
        setSelectedTeam({ label: "---", value: null });
      }
    } catch (error) {
      console.error("Error al obtener el equipo actual:", error);
      setSelectedTeam({ label: "---", value: null });
    }
  };

  const resolveRequest = (requestId, accept) => {
    const token = getToken();

    const requestBody = {
      Id: requestId,
      Accept: accept,
    };

    console.log("Body de la solicitud:", requestBody); // Agregar este console.log para ver el cuerpo de la solicitud

    fetch(api + "/teams/request-resolve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Hubo un problema con la petición Fetch");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Solicitud resuelta", data);
        // Actualizar el estado de las solicitudes pendientes después de resolver la solicitud
        setPendingRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== requestId)
        );
      })
      .catch((error) => {
        console.error("Error al resolver la solicitud:", error);
      });
  };

  useEffect(() => {
    // Si no hay solicitudes pendientes después de cargar, actualiza el estado para reflejarlo
    if (pendingRequests.length === 0) {
      setPendingRequests([]);
    }
  }, [pendingRequests.length]); // Incluye pendingRequests.length en el array de dependencias

  const fetchPendingRequests = () => {
    const token = getToken();

    if (!token) {
      console.error("No se encontró un token de usuario.");
      return;
    }

    fetch(api + "/teams/requests", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && Array.isArray(data.data)) {
          setPendingRequests(data.data);
        } else {
          setPendingRequests([]);
        }
      })
      .catch((error) => {
        console.error("Error al obtener solicitudes pendientes:", error);
        setPendingRequests([]);
      });
  };

  const fetchTeams = () => {
    fetch(api + "/teams")
      .then((response) => response.json())
      .then((data) => {
        const options = data.data.map((team) => ({
          value: team.id,
          label: team.name,
        }));
        setTeamsOptions(options);
        console.log("Equipos obtenidos:", options);
      })
      .catch((error) => console.error("Error al obtener equipos:", error));
  };

  useEffect(() => {
    fetchTeams();
    fetchCurrentTeam();
    fetchPendingRequests();
  }, []);

  const handleTeamChange = (selectedOption) => {
    setTeamToInvite(selectedOption);
  };

  const inviteTeam = async () => {
    if (!teamToInvite) {
      alert("Por favor, selecciona un equipo para enviar una solicitud.");
      return;
    }
    setIsInviting(true); // Indica que el proceso de invitación ha comenzado
    const teamId = teamToInvite.value;

    const token = getToken();

    try {
      const response = await fetch(`${api}/teams/request-team/${teamId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(
          "Hubo un problema al enviar la solicitud de invitación"
        );
      }

      alert(`Solicitud enviada a: ${teamToInvite.label}`);
      console.log(`Solicitud enviada a: ${teamToInvite.label}`);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      alert("Error al enviar la solicitud. Por favor, inténtalo de nuevo.");
    } finally {
      setIsInviting(false); // Restablece el estado independientemente del resultado
    }
  };

  useEffect(() => {
    const currentHash = location.hash.replace("#", "");
    setActiveTab(currentHash || "datos");
  }, [location]);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerPicChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const changeTab = (tabName) => {
    window.location.hash = tabName;
  };

  return (
    <div className="user-profile">
      <div className="profile-picture">
        <input
          type="file"
          id="profile-upload"
          className="upload-input"
          onChange={handleProfilePicChange}
        />
        <label htmlFor="profile-upload" className="profile-overlay"></label>
        <button
          className="upload-btn"
          onClick={() => document.getElementById("profile-upload").click()}
        >
          <FaCamera color="#ffffff" />
        </button>
        <img src={profilePic} alt="Profile" className="profile-image" />
      </div>
      <div className="banner">
        <input
          type="file"
          id="banner-upload"
          className="upload-input"
          onChange={handleBannerPicChange}
        />
        <label htmlFor="banner-upload" className="banner-overlay"></label>
        <button
          className="upload-btn"
          onClick={() => document.getElementById("banner-upload").click()}
        >
          <FaCamera color="#ffffff" />
        </button>
        <img src={bannerPic} alt="Banner" className="banner-image" />
      </div>
      <div className="user-profile">
        <nav className="mini-navbar">
          <ul>
            <li
              className={`nav-link ${activeTab === "datos" ? "active" : ""}`}
              onClick={() => changeTab("datos")}
            >
              DATOS
            </li>
            <li
              className={`nav-link ${
                activeTab === "membresia" ? "active" : ""
              }`}
              onClick={() => changeTab("#")}
            >
              MEMBRESÍA/PAGOS
            </li>
          </ul>
        </nav>
        <div>
          {activeTab === "datos" && (
            <div className="players-container">
              <ul>
                <div className="team-member-title">
                  <div className="member-info">
                    <div className="member-details">
                      <div className="integrantes-label">Equipo Actual</div>
                    </div>
                  </div>
                  {/* Información del equipo actual */}
                  <div className="member-info">
                    <div className="member-details">
                      {/* Nombre del equipo */}
                      <div className="nombre-real">
                        {selectedTeam
                          ? selectedTeam.label
                          : "Cargando equipo..."}
                      </div>
                    </div>
                    {selectedTeam &&
                      selectedTeam.value && ( // Verifica si hay un equipo seleccionado
                        <button
                          onClick={() => {
                            // Lógica para manejar la salida del equipo
                          }}
                          className="btn-eliminar"
                        >
                          SALIR DEL EQUIPO
                        </button>
                      )}
                  </div>
                </div>
                {/* Lista de solicitudes pendientes */}
                <div className="team-member-title">
                  <div className="member-info">
                    <div className="member-details">
                      <div className="integrantes-label">
                        Solicitudes Pendientes
                      </div>
                    </div>
                  </div>
                </div>
                {pendingRequests.length > 0 ? (
                  pendingRequests.map((request, index) => (
                    <li key={request.id} className="team-member">
                      <div className="member-info">
                        <div className="member-details">
                          {/* Nombre del equipo (ya sea el que envió la solicitud o al que se le envió) */}
                          <div className="nombre-real">{request.teamName}</div>
                        </div>
                        <div className="member-details">
                          {/* Si la solicitud fue creada por el jugador, mostrar solo el botón Eliminar */}
                          {request.createdByPlayer ? (
                            <button
                              onClick={() => resolveRequest(request.id, false)}
                              className="btn-eliminar"
                            >
                              Eliminar
                            </button>
                          ) : (
                            // Si la solicitud fue creada por el equipo, muestra los botones Aceptar y Rechazar
                            <>
                              <button
                                onClick={() => resolveRequest(request.id, true)}
                                className="btn-aceptar"
                              >
                                Aceptar
                              </button>
                              <button
                                onClick={() =>
                                  resolveRequest(request.id, false)
                                }
                                className="btn-eliminar"
                              >
                                Rechazar
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="nombre-real">{"---"}</div>
                )}
              </ul>
              <div className="playerSelector">
                <label>Enviar una solicitud a un equipo</label>
                <div className="invite-container">
                  <Select
                    options={teamOptions}
                    onChange={handleTeamChange}
                    placeholder="Ingrese el nombre del team..."
                    isClearable
                    isSearchable
                    value={teamOptions.find(
                      (option) => option.value === teamToInvite
                    )}
                  />
                  <button
                    onClick={inviteTeam}
                    className="inviteButton"
                    disabled={isInviting}
                  >
                    {isInviting ? "Enviando..." : "Enviar solicitud"}
                  </button>
                </div>
              </div>
              <TournamentCard
                link="MIS PRÓXIMOS PARTIDOS"
                fixedResult="VS"
                matchDate="2024-03-12"
              />
            </div>
          )}
          {activeTab === "membresia" && <div></div>}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
