import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getNames } from "country-list";
import "../styles/css/main.css";
import { useAuth } from "../components/AuthContext";

const SignUpForm = () => {
  const [username, setUsername] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [pais, setPais] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  const [contraseniaVerificada, setContraseniaVerificada] = useState("");
  const [nickname, setNickname] = useState("");
  const [errorContrasenia, setErrorContrasenia] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamsOptions, setTeamsOptions] = useState([]);
  const { register } = useAuth();

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await fetch("https://imapi.keencon.org/teams");
      const data = await response.json();
      const options = data.data.map((team) => ({
        value: team.id,
        label: team.name,
      }));
      setTeamsOptions(options);
      console.log(options);
    } catch (error) {
      console.error("Error al obtener equipos:", error);
    }
  };

  const navigate = useNavigate();

  const countryOptions = getNames().map((country) => ({
    value: country,
    label: country,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (contrasenia !== contraseniaVerificada) {
      setErrorContrasenia("Las contraseñas no coinciden");
      return;
    }

    const userData = {
      Username: username,
      nickname: nickname,
      FullName: nombreCompleto,
      Country: pais,
      DateOfBirth: fechaNacimiento,
      Email: correoElectronico,
      Password: contrasenia,
    };

    try {
      await register(userData);
      console.log("Registro exitoso");
      localStorage.setItem("verifyingToken", "true");
      console.log("verifyingToken establecido correctamente en localStorage");
      navigate("/verify-token?status=success");
    } catch (error) {
      console.error("Error en el registro:", error);
    }
  };

  return (
    <div className="card">
      <h2>Registra tu cuenta</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Nombre Completo</label>
          <input
            type="text"
            value={nombreCompleto}
            onChange={(e) => setNombreCompleto(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Nickname</label>
          <input
            type="text"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            required
          />
        </div>
        <div className="countrySelector">
          <label>País</label>
          <Select
            options={countryOptions}
            onChange={(selectedOption) =>
              setPais(selectedOption ? selectedOption.value : "")
            }
            placeholder="Seleccionar país..."
            isClearable
            isSearchable
          />
        </div>
        <div>
          <label>Fecha de Nacimiento</label>
          <input
            type="date"
            value={fechaNacimiento}
            onChange={(e) => setFechaNacimiento(e.target.value)}
            required
          />
        </div>
        <div className="countrySelector">
          <label>Equipo</label>
          <Select
            options={teamsOptions}
            onChange={(selectedOption) =>
              setSelectedTeam(selectedOption ? selectedOption.value : "")
            }
            placeholder="Selecciona tu equipo..."
            isClearable
            isSearchable
          />
        </div>
        <div>
          <label>Correo Electrónico</label>
          <input
            type="email"
            value={correoElectronico}
            onChange={(e) => setCorreoElectronico(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Contraseña</label>
          <input
            type="password"
            minLength="8"
            value={contrasenia}
            onChange={(e) => setContrasenia(e.target.value)}
            placeholder="Mínimo 8 caracteres"
            required
          />
        </div>
        <div>
          <label>Verificar Contraseña</label>
          <input
            type="password"
            minLength="8"
            value={contraseniaVerificada}
            onChange={(e) => setContraseniaVerificada(e.target.value)}
            placeholder="Repite la contraseña"
            required
          />
          {errorContrasenia && <p>{errorContrasenia}</p>}
        </div>
        <button type="submit">Registrarse</button>
      </form>
      <div>
        <p>
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;